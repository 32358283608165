// Generated by Framer (7aa721f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {ZwEAVp_0o: {hover: true}};

const cycleOrder = ["ZwEAVp_0o", "tvnnzO8RS"];

const variantClassNames = {tvnnzO8RS: "framer-v-13c3rla", ZwEAVp_0o: "framer-v-zvk0sw"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 40, delay: 0, mass: 1, stiffness: 300, type: "spring"}};

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "ZwEAVp_0o", "Variant 2": "tvnnzO8RS"}

const getProps = ({height, id, image, tap, width, ...props}) => { return {...props, nRFQUa9uZ: tap ?? props.nRFQUa9uZ, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ZwEAVp_0o", xgwxGcsYI: image ?? props.xgwxGcsYI ?? {src: new URL("assets/512/64n4wnVJKDJspLlFZ6DarCP0M4.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/64n4wnVJKDJspLlFZ6DarCP0M4.jpg", import.meta.url).href} 341w, ${new URL("assets/1024/64n4wnVJKDJspLlFZ6DarCP0M4.jpg", import.meta.url).href} 682w, ${new URL("assets/64n4wnVJKDJspLlFZ6DarCP0M4.jpg", import.meta.url).href} 1000w`}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, nRFQUa9uZ, xgwxGcsYI, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ZwEAVp_0o", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1kfizsr = activeVariantCallback(async (...args) => {
if (nRFQUa9uZ) {
const res = await nRFQUa9uZ(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-C3aE2", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-zvk0sw", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"ZwEAVp_0o"} onTap={onTap1kfizsr} ref={ref} style={{borderBottomLeftRadius: 5, borderBottomRightRadius: 5, borderTopLeftRadius: 5, borderTopRightRadius: 5, ...style}} variants={{"ZwEAVp_0o-hover": {borderBottomLeftRadius: 15, borderBottomRightRadius: 15, borderTopLeftRadius: 15, borderTopRightRadius: 15}}} {...addPropertyOverrides({"ZwEAVp_0o-hover": {"data-framer-name": undefined}, tvnnzO8RS: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fill", intrinsicHeight: 3002, intrinsicWidth: 2000, pixelHeight: 3002, pixelWidth: 2000, sizes: "min(320px, 100vw)", ...toResponsiveImage(xgwxGcsYI)}} className={"framer-52i5wj"} data-framer-name={"Photo"} layoutDependency={layoutDependency} layoutId={"Kzz9_a3qy"} style={{borderBottomLeftRadius: 15, borderBottomRightRadius: 15, borderTopLeftRadius: 15, borderTopRightRadius: 15}} variants={{"ZwEAVp_0o-hover": {borderBottomLeftRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: 0, borderTopRightRadius: 0}}} {...addPropertyOverrides({"ZwEAVp_0o-hover": {background: {alt: "", fit: "fill", intrinsicHeight: 3002, intrinsicWidth: 2000, pixelHeight: 3002, pixelWidth: 2000, sizes: "calc(min(320px, 100vw) + 32px)", ...toResponsiveImage(xgwxGcsYI)}}}, baseVariant, gestureVariant)}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-C3aE2 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-C3aE2 .framer-17u3q8m { display: block; }", ".framer-C3aE2 .framer-zvk0sw { cursor: pointer; height: 220px; overflow: hidden; position: relative; width: 320px; will-change: var(--framer-will-change-override, transform); }", ".framer-C3aE2 .framer-52i5wj { -webkit-user-select: none; aspect-ratio: 1.4545454545454546 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 220px); left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; user-select: none; }", ".framer-C3aE2 .framer-v-zvk0sw .framer-zvk0sw { cursor: pointer; }", ".framer-C3aE2.framer-v-zvk0sw.hover .framer-52i5wj { aspect-ratio: unset; bottom: -11px; height: unset; left: -16px; right: -16px; top: -11px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 220
 * @framerIntrinsicWidth 320
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"tvnnzO8RS":{"layout":["fixed","fixed"]},"q8hvQ5zIZ":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"nRFQUa9uZ":"tap","xgwxGcsYI":"image"}
 */
const FramerxOMbSAJ6J: React.ComponentType<Props> = withCSS(Component, css, "framer-C3aE2") as typeof Component;
export default FramerxOMbSAJ6J;

FramerxOMbSAJ6J.displayName = "Thumbnail";

FramerxOMbSAJ6J.defaultProps = {height: 220, width: 320};

addPropertyControls(FramerxOMbSAJ6J, {variant: {options: ["ZwEAVp_0o", "tvnnzO8RS"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, nRFQUa9uZ: {title: "Tap", type: ControlType.EventHandler}, xgwxGcsYI: {__defaultAssetReference: "data:framer/asset-reference,64n4wnVJKDJspLlFZ6DarCP0M4.jpg?originalFilename=Flowers+1.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerxOMbSAJ6J, [])